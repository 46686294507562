import { DecimalPipe } from '@angular/common';
import { Params } from '@angular/router';

/**
 * a fixed, default value (GTC is set to 1.5 by default)
 * https://zonarsystems.atlassian.net/wiki/spaces/GTCX/pages/137815458028/Idle+Table+Component+Breakdown
 */

export const isLocalDevEnv = () => {
  // in order for unit tests (ran from localhost:9876) to pass, they need to be excluded from 'local'
  return (
    window?.location?.origin?.includes('localhost:4200') ||
    window?.location?.origin?.includes('local.dev')
  );
};

// Source: https://stackoverflow.com/a/53943624
export function repeatTimes(times: number) {
  return {
    [Symbol.iterator]: function* () {
      for (let i = 0; i < times; i++, yield) {}
    }
  };
}

export function isArrayEquals(arr1: Array<any>, arr2: Array<any>) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] != arr2[i]) {
      return false;
    }
  }
  return true;
}

/**
 * Compare 2 values, only support primitive type, string, array
 * @param a
 * @param b
 */
export function equals(a, b) {
  if (typeof a !== typeof b) {
    return false;
  } else if (Array.isArray(a) && Array.isArray(b)) {
    return isArrayEquals(a, b);
  } else {
    return a == b;
  }
}

export function isFilterParamsChanged(previous: Params, current: Params) {
  if (!previous || !current) {
    return false;
  }
  const prevParams = { ...previous };
  const currentParams = { ...current };
  // remove pagination params
  delete prevParams.sort_by;
  delete prevParams.sort_order;
  delete prevParams.page;
  delete prevParams.per_page;
  delete currentParams.sort_by;
  delete currentParams.sort_order;
  delete currentParams.page;
  delete currentParams.per_page;

  const prevKeys = Object.keys(prevParams);
  const currentKeys = Object.keys(currentParams);
  if (prevKeys.length != currentKeys.length) {
    return false;
  }
  for (const key of prevKeys) {
    if (!equals(currentParams[key], prevParams[key])) {
      return false;
    }
  }
  return true;
}

export function isPaginationParamsChanged(previous: Params, current: Params) {
  return (
    previous?.sort_by != current?.sort_by ||
    previous?.sort_order != current?.sort_order ||
    previous?.page != current?.page ||
    (previous?.per_page && previous?.per_page != current.per_page)
  );
}

export function isAllParamsChanged(previous: Params, current: Params) {
  if (!previous || !current) {
    return false;
  }
  const prevKeys = Object.keys(previous);
  const currentKeys = Object.keys(current);
  if (prevKeys.length != currentKeys.length) {
    return false;
  }
  for (const key of prevKeys) {
    if (!equals(current[key], previous[key])) {
      return false;
    }
  }
  return true;
}

export function convertDuration(
  value: string | number,
  decimalPipe: DecimalPipe
): string {
  const hrs = Number(value);
  const hh = decimalPipe.transform(Math.trunc(hrs), '2.0-0');
  const mm = ('0' + Math.trunc((hrs * 60) % 60)).slice(-2);
  const ss = ('0' + Math.trunc((hrs * 3600) % 60)).slice(-2);

  return `${hh}h ${mm}m ${ss}s`;
}

export function convertMillimeterPerSecToMilesPerHour(value: number): number {
  return value * 0.00223693629;
}

export function removeInvalidSortParams(
  params: Params,
  sortableColumns: string[]
): Params {
  const newParams = { ...params };
  if (newParams.sort_by && !sortableColumns.includes(newParams.sort_by)) {
    delete newParams.sort_by;
    delete newParams.sort_order;
  }
  return newParams;
}

export function convertMetersToMiles(
  value: string | number,
  decimalPipe: DecimalPipe
): string {
  return decimalPipe.transform(Number(value) / 1609.34, '1.1-1');
}

export function isNumber(value: any): boolean {
  return value != null && value != undefined && !isNaN(value);
}

/**
 * Covert deciliters per US gallons
 * @param value the value in deciliters
 * @param digits The number of digits to appear after the decimal point, default is 1.
 * @returns
 */
export function convertDeciliterToGallon(
  value: string | number,
  decimalPipe: DecimalPipe
): string {
  return decimalPipe.transform(Number(value) * 0.026417205, '1.1-1');
}

export function parseError(context: any) {
  if (context.status >= 500) {
    return 'overview.tableError.serverError';
  } else if (context.status === 422) {
    return 'overview.tableError.userInputError';
  } else if (context.status === 401 || context.status === 403) {
    return 'overview.tableError.sessionError';
  } else {
    return 'overview.tableError.UnknownError';
  }
}

export function camelToSnake(string: string) {
  return string.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
}

<div class="overview-container">
  <app-page-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h1>
        {{ translateService.get(translations?.overview.pageTitle) | async |
        translate }}
      </h1>
    </div>
  </app-page-title>

  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      *ngFor="let link of navLinks"
      mat-tab-link
      #rla="routerLinkActive"
      [active]="rla.isActive"
      [routerLinkActiveOptions]="{exact: false}"
      [routerLink]="link.path"
      queryParamsHandling="preserve"
      routerLinkActive>
      {{ translateService.get(translations.overview.matTabs[link.label]) | async
      | translate }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
